import React from 'react'
import PageBannerImgBackground from '../PageBannerImgBackground/pageBannerImgBackground'
import './twoColumnListLayout.scss'
import SpotlightActivity from '../SpotlightActivity/spotlightActivity'

 const TwoColumnListLayout = ({
    topicList, 
    pageBannerImage,
    pageBannerTitle,
    pageBannerDescription, 
    spotlightActivity = 'true'
}) => {



        const getImagePath = (image) => {
            if (image?.publicURL) return image.publicURL
            else return image
          }


    return (
        <div className='twoColumnListLayout-wrapper'>
        <PageBannerImgBackground
        imgPath={getImagePath(pageBannerImage)}
        title={pageBannerTitle}
        description={pageBannerDescription}
        />
            <div className='body-content-wrapper'>
                    {topicList && topicList.map(item => <div key={item.title} className='description-block'>
                    <a href={item.link}>
                        <div className='image-wrapper' style={{background: `url(${getImagePath(item.thumbnail)} )`}}> 
                        </div> 
                    </a>
                        <div className='description-wrapper'>
                            <div className='title'>
                            <a href={item.link}> {item.title} </a>
                            </div>
                            <div className='second-title'> {item.date} </div>
                            <div className='description'>
                                {item.description}
                            </div>
                            <button className='btn-default'><a href={item.link}> Read More </a></button>
                        </div>
                    </div>)}
                </div>

        </div> 
    )
}


export default TwoColumnListLayout
