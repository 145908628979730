import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TwoColumnListLayout from '../components/TwoColumnListLayout/twoColumnListLayout'

// import remark from 'remark'
// import remarkHTML from 'remark-html'


export const BlogTemplate = ({
  pageBannerDescription,
  pageBannerTitle,
  pageBannerImage,
  blogList,
}) => {




//   const PageContent = contentComponent || Content

  return (
    <>
    <TwoColumnListLayout 
        topicList={blogList}
        pageBannerImage={pageBannerImage}
        pageBannerTitle={pageBannerTitle}
        pageBannerDescription={pageBannerDescription}
        spotlightActivity={false}
    />
    </>
  )
}

BlogTemplate.propTypes = {
    // bannerImg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    blogList: PropTypes.array,
    spotlightActivity: PropTypes.array,
    pageBannerDescription: PropTypes.string,
    pageBannerTitle: PropTypes.string,
    pageBannerImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }

  const BlogPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark
      return (
        <Layout
        title={frontmatter.pageBannerTitle}
        description={frontmatter.pageBannerDescription}
        >
              <BlogTemplate
                blogList={frontmatter.blogList}
                spotlightActivity={frontmatter.spotlightActivity}
                pageBannerDescription={frontmatter.pageBannerDescription}
                pageBannerTitle={frontmatter.pageBannerTitle}
                pageBannerImage={frontmatter.pageBannerImage}
              />
        </Layout>
      )
}
 
BlogPage.propTypes = {
    data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    }),
}

export default BlogPage


export const pageQuery = graphql`
  query BlogTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "blog-hub" } }) {
      frontmatter {
        pageBannerDescription
        pageBannerTitle
        pageBannerImage {
          extension
          publicURL
        } 
        blogList {
            title
            date
            link
            description
            thumbnail {
              extension
              publicURL
            }
         }
         spotlightActivity {
          title
          link
          description 
          thumbnail {
              extension
              publicURL
            }
          }
        }
    }
  }
`
